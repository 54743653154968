const defaultLang = 'en-gb'

const processPath = (path, _locale, type = 'page') => {
    const locale = _locale.toLowerCase()

    switch (type) {
        case 'page':
            if (path === 'home')
                return locale === defaultLang ? `/` : `/${locale}`
            return locale === defaultLang ? `/${path}` : `/${locale}/${path}`
        case 'post':
            return locale === defaultLang
                ? `/news/${path}`
                : `/news/${locale}/${path}`
        case 'character':
            return locale === defaultLang
                ? `/characters/${path}`
                : `/${locale}/characters/${path}`
    }
}

const isHome = (slug) => {
    return (
        slug.indexOf('home') >= 0 &&
        typeof window !== 'undefined' &&
        window.location.pathname === '/'
    )
}

export default {
    processPath,
    isHome,
}
